import { useState } from 'react'

import * as yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'
import { v4 as uuidv4 } from 'uuid'

import { Checkbox, Grid, MenuItem, FormControlLabel } from '@material-ui/core'
import {
  ActionDialog,
  ContentDivider,
  TextField,
  NumeroInscricaoTextField,
  HoraTextField,
  DatePickerNew,
} from '~/components'
import { AutoCompleteCidade, MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { PessoaFisicaDependente } from '~/hooks/queries/PessoaFisicaDependente/PessoaFisicaDependente'

import { GrauParentescoEnum, GrauParentescoValues } from '~/@types/enums/GrauParentescoEnum'

import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const schema = yup.object().shape({
  grauParentesco: yup.string().required('Informe o Grau Parentesco'),
  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .when(['isDependenteIR'], (isDependenteIR, schema) => {
      if (isDependenteIR === true) {
        return schema
          .required('Informe o CPF')
          .test('cpf-validator', 'Informe um CPF válido', (nrInscricao: string) =>
            cpf.isValid(nrInscricao),
          )
      }

      return schema
    })
    .test('CPF-Validator', 'Informe um CPF válido', (nrInscricao) => {
      if (nrInscricao && nrInscricao.length > 0) return cpf.isValid(nrInscricao)
      return true
    })
    .nullable(),
  descricaoDependente: yup
    .string()
    .when(['grauParentesco'], (grauParentesco, schema) =>
      parseInt(grauParentesco) === GrauParentescoEnum.AgregadoOutros_99
        ? schema.required('Informe a Descrição do Dependente')
        : schema,
    )
    .nullable(),
  dtNascimento: yup
    .date()
    .required('Informe a Data de Nascimento')
    .typeError('Informe uma data válida')
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  afterSubmit: (event: 'update' | 'insert', data: PessoaFisicaDependente) => void
  data: Partial<PessoaFisicaDependente>
}

export function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, afterSubmit } = props

  const [data, setData] = useState(_data)

  const notification = useNotification()

  const handleSubmit = () => {
    const update = () => {
      afterSubmit('update', data as PessoaFisicaDependente)
      notification.put()
    }

    const insert = () => {
      data.id = uuidv4()
      afterSubmit('insert', data as PessoaFisicaDependente)
      notification.post()
    }

    if (data.id) {
      return update()
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Pessoa Física | Dependentes"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            size="small"
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
          <MUIAutoComplete
            label="Grau Parentesco"
            options={GrauParentescoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.grauParentesco}
            required
            validationErrors={validationErrors}
            name="grauParentesco"
            onChange={(e, obj) => {
              const grauParentesco = obj ? obj.value : ''
              setData({
                ...data,
                grauParentesco,
                descricaoDependente: '',
              })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Data Nascimento"
            size="small"
            value={data.dtNascimento || null}
            onChange={(date) => {
              const dtNascimento = date || undefined
              setData({ ...data, dtNascimento })
            }}
            required
            validationErrors={validationErrors}
            name="dtNascimento"
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <NumeroInscricaoTextField
            label="CPF"
            name="nrInscricao"
            typeMask={TipoInscricaoEnum.CPF_2}
            validationErrors={validationErrors}
            value={data?.nrInscricao || ''}
            onChange={(e, value) => {
              const nrInscricao = value
              setData({ ...data, nrInscricao })
            }}
            required={data?.isDependenteIR === true}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            label="Matricula"
            fullWidth
            value={data.matricula || ''}
            size="small"
            inputProps={{
              maxLength: 32,
            }}
            onChange={(e) => {
              const matricula = e.target.value
              setData({ ...data, matricula })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            name="descricaoDependente"
            label="Descrição do Dependente"
            size="small"
            required={data?.grauParentesco === GrauParentescoEnum.AgregadoOutros_99}
            disabled={data?.grauParentesco !== GrauParentescoEnum.AgregadoOutros_99}
            fullWidth
            inputProps={{ maxLength: 30 }}
            value={data?.descricaoDependente || ''}
            onChange={(e) => {
              const descricaoDependente = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                descricaoDependente,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Local Nascimento"
            fullWidth
            value={data.localNascimento || ''}
            size="small"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const localNascimento = e.target.value
              setData({ ...data, localNascimento })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <HoraTextField
            label="Hora Nascimento"
            fullWidth
            value={data.horaNascimento || null}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const horaNascimento = e.target.value.replace(/\D/g, '')
              setData({ ...data, horaNascimento })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            label="Cartório"
            fullWidth
            value={data.cartorio || ''}
            size="small"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const cartorio = e.target.value
              setData({ ...data, cartorio })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Registro"
            fullWidth
            value={data.registro || ''}
            size="small"
            inputProps={{
              maxLength: 20,
            }}
            onChange={(e) => {
              const registro = e.target.value
              setData({ ...data, registro })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            label="Livro"
            fullWidth
            value={data.livro || ''}
            size="small"
            inputProps={{
              maxLength: 10,
            }}
            onChange={(e) => {
              const livro = e.target.value
              setData({ ...data, livro })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <TextField
            label="Folha"
            fullWidth
            value={data.folha || ''}
            size="small"
            inputProps={{
              maxLength: 10,
            }}
            onChange={(e) => {
              const folha = e.target.value
              setData({ ...data, folha })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Registro"
            size="small"
            value={data.dtRegistro || null}
            onChange={(date) => {
              const dtRegistro = date
              setData({ ...data, dtRegistro })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Exclusão SF"
            size="small"
            value={data.dtExclusaoSF || null}
            onChange={(date) => {
              const dtExclusaoSF = date
              setData({ ...data, dtExclusaoSF })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Exclusão IR"
            size="small"
            value={data.dtExclusaoIR || null}
            onChange={(date) => {
              const dtExclusaoIR = date
              setData({ ...data, dtExclusaoIR })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Vacinação"
            size="small"
            value={data.dtCadernetaVacinacao || null}
            onChange={(date) => {
              const dtCadernetaVacinacao = date
              setData({ ...data, dtCadernetaVacinacao })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Frequência Escolar"
            size="small"
            value={data.dtFrequenciaEscolar || null}
            onChange={(date) => {
              const dtFrequenciaEscolar = date
              setData({ ...data, dtFrequenciaEscolar })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <AutoCompleteCidade
            label="Registro Cidade"
            value={data?.registroCidadeId || ''}
            optionId="id"
            onChange={(e, registroCidade) => {
              const registroCidadeId = registroCidade?.id || ''
              setData({ ...data, registroCidadeId, registroCidade })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <TextField
            select
            label="Sexo"
            fullWidth
            size="small"
            value={data.sexo || ''}
            onChange={(e) => {
              const sexo = e.target.value
              setData({ ...data, sexo })
            }}
          >
            <MenuItem value="M">Masculino</MenuItem>
            <MenuItem value="F">Feminino</MenuItem>
          </TextField>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={6} xs={12}>
          <TextField
            label="Declaração Nascido Vivo"
            fullWidth
            value={data.declaracaoNascidoVivo || ''}
            size="small"
            inputProps={{
              maxLength: 20,
            }}
            onChange={(e) => {
              const declaracaoNascidoVivo = e.target.value
              setData({ ...data, declaracaoNascidoVivo })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            size="small"
            fullWidth
            multiline
            rows={4}
            value={data.observacao || ''}
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Dependente SF"
            control={
              <Checkbox
                // checked={data.isDependenteSF}
                checked={data.isDependenteSF || false}
                size="small"
                onChange={(e) => {
                  const isDependenteSF = e.target.checked
                  setData({ ...data, isDependenteSF })
                }}
                color="secondary"
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Dependente IR"
            control={
              <Checkbox
                checked={data?.isDependenteIR || false}
                size="small"
                onChange={(e) => {
                  const isDependenteIR = e?.target?.checked
                  setData({ ...data, isDependenteIR })
                }}
                color="secondary"
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Deficiente"
            control={
              <Checkbox
                checked={data.isDeficiente || false}
                size="small"
                onChange={(e) => {
                  const isDeficiente = e.target.checked
                  setData({ ...data, isDeficiente })
                }}
                color="secondary"
              />
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
