import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'
import { AutoCompletePessoaFisicaDependenteVinculo } from '~/components/AutoComplete'

import * as yup from 'yup'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/VinculoPensaoDependente/useCriar'
import { useAtualizar } from '~/hooks/queries/VinculoPensaoDependente/useAtualizar'

import { VinculoPensaoDependente } from '~/hooks/queries/VinculoPensaoDependente/entities/VinculoPensaoDependente'

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .required('Informe Data de Início')
    .typeError('Informe uma data válida')
    .nullable(),
  pessoaFisicaDependenteId: yup.string().required('Informe o Dependente').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoPensaoDependente | null
  vinculoPensaoId: string
  vinculoId: string
}

export default function Form({
  isOpen,
  onClose,
  data: _data,
  vinculoPensaoId,
  vinculoId,
}: FormProps) {
  const [data, setData] = useState(
    _data ||
      ({
        vinculoPensaoId,
      } as VinculoPensaoDependente),
  )

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  const { mutateAsync: mutateAsyncCriar, isLoading: _isLoadingCriar } = useCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: _isLoadingAtualizar } = useAtualizar()

  const isSubmitting = _isLoadingCriar || _isLoadingAtualizar

  async function handleSubmit() {
    if (_data) {
      await mutateAsyncAtualizar({
        data,
        params: {
          id: data.id,
        },
      })
    } else {
      await mutateAsyncCriar({
        data,
      })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Pensão Alimentícia"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Início"
            size="small"
            value={data?.dtInicio || null}
            required
            validationErrors={validationErrors}
            name="dtInicio"
            onChange={(d) => {
              const dtInicio = d || ''
              setData((prev) => ({ ...prev, dtInicio }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Fim"
            size="small"
            value={data?.dtFim || null}
            onChange={(d) => {
              const dtFim = d
              setData((prev) => ({ ...prev, dtFim }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutoCompletePessoaFisicaDependenteVinculo
            required
            validationErrors={validationErrors}
            name="pessoaFisicaDependenteId"
            value={data.pessoaFisicaDependenteId}
            onChange={(e, d) => {
              const pessoaFisicaDependenteId = d?.id || ''
              setData((prev) => ({
                ...prev,
                pessoaFisicaDependenteId,
              }))
            }}
            vinculoId={vinculoId}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
