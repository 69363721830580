import { makeStyles } from '@material-ui/core'

import {
  ActionDialog,
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Stack,
} from '~/components'

import Form from './components/Form'
import Table from './components/Table'

import useDialog from '~/hooks/useDialog'

import { useObterPorPensao } from '~/hooks/queries/VinculoPensaoDependente/useObterPorPensao'
import { useRemover } from '~/hooks/queries/VinculoPensaoDependente/useRemover'

import { VinculoPensaoDependente } from '~/hooks/queries/VinculoPensaoDependente/entities/VinculoPensaoDependente'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

interface DependentesProps {
  isOpen: boolean
  onClose: () => void
  vinculoPensaoId: string
  vinculoId: string
}

export default function Dependentes({
  isOpen,
  onClose,
  vinculoPensaoId,
  vinculoId,
}: DependentesProps) {
  const classes = useStyles()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoPensaoDependente | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorPensao({
    params: {
      vinculoPensaoId,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemover()

  function onItemClickTable(event: 'edit' | 'delete', value: VinculoPensaoDependente) {
    switch (event) {
      case 'edit':
        openForm(value)
        break
      case 'delete':
        openConfirmDelete(value.id)
        break
    }
  }

  async function handleDeleteItem() {
    await mutateAsyncRemove({
      params: {
        id: dataConfirmDelete,
      },
    })
    closeConfirmDelete()
  }

  return (
    <ActionDialog
      title="Dependentes da Pensão Alimentícia"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
    >
      <Stack height="100%">
        <Stack alignItems="flex-end">
          <Button variant="contained" onClick={() => openForm(null)}>
            Adicionar
          </Button>
        </Stack>
        <ContainerTable>
          <Table
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            onItemClick={onItemClickTable}
          />
        </ContainerTable>
      </Stack>

      {isOpenForm && (
        <Form
          data={dataForm}
          isOpen={isOpenForm}
          onClose={closeForm}
          vinculoPensaoId={vinculoPensaoId}
          vinculoId={vinculoId}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
          isDeleting={isRemoving}
        />
      )}
    </ActionDialog>
  )
}
