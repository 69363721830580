import React, { useMemo } from 'react'

import { IconButton, makeStyles } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'
import { formatSimpleDate } from '~/utils/utils'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const classes = useStyles()

  const { formatAnoMes, formatCurrency } = useUtils()

  const dataFormated = data.map((data) => {
    return {
      ...data,
      nome: data?.adquirente?.nome,
      dtEmissaoDocumento: moment(data?.dtEmissaoDocumento).format('DD/MM/YYYY'),
      anoMes: formatAnoMes(data?.anoMes),
      vrBruto: formatCurrency(data?.vrBruto),
      vrContribuicaoPrevidenciaria: formatCurrency(data?.vrContribuicaoPrevidenciaria),
      vrRat: formatCurrency(data?.vrRat),
      vrSenar: formatCurrency(data?.vrSenar),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'dtEmissaoDocumento',
        label: 'Data de Emissão',
      },
      {
        name: 'anoMes',
        label: 'Ano/Mês',
      },
      {
        name: 'serie',
        label: 'Série',
      },
      {
        name: 'nrDocumento',
        label: 'Número da Nota Fiscal',
      },
      {
        name: 'vrBruto',
        label: 'Valor Bruto',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrContribuicaoPrevidenciaria',
        label: 'Contribuição Previdenciária',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrRat',
        label: 'Valor do RAT',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrSenar',
        label: 'Valor do SENAR',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'dtReciboEsocial',
        label: 'Data Recibo eSocial',
        options: {
          customBodyRender: formatSimpleDate,
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    // eslint-disable-next-line
    [onItemClick],
  )

  return <DataTable title="" data={dataFormated} columns={columns} isLoading={isLoading} />
}

Table.defaultProps = {
  query: '',
}

export default Table
