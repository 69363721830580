import { useMemo } from 'react'

import { ToolsDataTable } from '~/components'
import { formatSimpleDateTime } from '~/utils/utils'

const Table = (props) => {
  const { data, isLoading, query, onRowSelected, rowsSelected, triggers } = props

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'dtReciboEsocial',
        label: 'Data Recibo eSocial',
        options: {
          customBodyRender: formatSimpleDateTime,
        },
      },
    ],
    [],
  )

  return (
    <ToolsDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      triggersHeight={triggers}
      sherlock={{
        query,
        columns: ['nome', 'reciboEsocial', 'dtReciboEsocial'],
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelected,
        selectOnClick: true,
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
