import { convertToArrayDescription } from '~/utils/utils'

export enum IndPensaoAlimenticiaEnum {
  SalarioMinimo_1 = 1,
  SalarioLiquido_2 = 2,
  SalarioBruto_3 = 3,
  Valor_4 = 4,
  NaoAplicavel_9 = 9,
}

export const IndPensaoAlimenticiaLabel = {
  [IndPensaoAlimenticiaEnum.NaoAplicavel_9]: '9 - Não informar',
  [IndPensaoAlimenticiaEnum.SalarioMinimo_1]: '1 - Percentual Salário Mínimo',
  [IndPensaoAlimenticiaEnum.SalarioLiquido_2]: '2 - Percentual Liquido',
  [IndPensaoAlimenticiaEnum.SalarioBruto_3]: '3 - Percentual Bruto',
  [IndPensaoAlimenticiaEnum.Valor_4]: '4 - Valor',
}

export const IndPensaoAlimenticiaValues = convertToArrayDescription(
  IndPensaoAlimenticiaEnum,
  IndPensaoAlimenticiaLabel,
)
