import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, CurrencyTextField, DatePickerNew, Checkbox } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import * as yup from 'yup'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/VinculoPensao/useCriar'
import { useAtualizar } from '~/hooks/queries/VinculoPensao/useAtualizar'

import { VinculoPensao } from '~/hooks/queries/VinculoPensao/entities/VinculoPensao'
import {
  IndPensaoAlimenticiaEnum,
  IndPensaoAlimenticiaValues as _IndPensaoAlimenticiaValues,
} from '~/@types/enums/IndPensaoAlimenticiaEnum'

const IndPensaoAlimenticiaValues = _IndPensaoAlimenticiaValues.filter(
  (d) => d.value !== IndPensaoAlimenticiaEnum.NaoAplicavel_9,
)

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .required('Informe Data de Início')
    .typeError('Informe uma data válida')
    .nullable(),
  vrPensao: yup
    .number()
    .required('Informe o Valor da Pensão')
    .test('valor-teste', 'Informe um Valor da Pensão válido', (value) =>
      value ? value > 0 : false,
    ),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoPensao | null
  vinculoId: string
}

export default function Form({ isOpen, onClose, data: _data, vinculoId }: FormProps) {
  const [data, setData] = useState(
    _data ||
      ({
        vinculoId,
        indPensao: IndPensaoAlimenticiaEnum.SalarioMinimo_1,
      } as VinculoPensao),
  )

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  const { mutateAsync: mutateAsyncCriar, isLoading: _isLoadingCriar } = useCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: _isLoadingAtualizar } = useAtualizar()

  const isSubmitting = _isLoadingCriar || _isLoadingAtualizar

  async function handleSubmit() {
    if (_data) {
      await mutateAsyncAtualizar({
        data,
        params: {
          id: data.id,
        },
      })
    } else {
      await mutateAsyncCriar({
        data,
      })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Pensão Alimentícia"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Início"
            size="small"
            value={data?.dtInicio || null}
            required
            validationErrors={validationErrors}
            name="dtInicio"
            onChange={(d) => {
              const dtInicio = d || ''
              setData((prev) => ({ ...prev, dtInicio }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Fim"
            size="small"
            value={data?.dtFim || null}
            onChange={(d) => {
              const dtFim = d
              setData((prev) => ({ ...prev, dtFim }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Indicador de Pensão"
            options={IndPensaoAlimenticiaValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.indPensao}
            onChange={(e, obj) => {
              const indPensao = obj?.value || IndPensaoAlimenticiaEnum.SalarioMinimo_1
              setData((prev) => ({ ...prev, indPensao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor da Pensão"
            value={data?.vrPensao || ''}
            required
            validationErrors={validationErrors}
            name="vrPensao"
            onChange={(_, value) => {
              const vrPensao = value
              setData((oldState) => ({
                ...oldState,
                vrPensao,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Checkbox
            label="Descontar no Décimo Terceiro"
            value={data?.isDescontaNoDecimoTerceiro}
            onChange={(e, value) => {
              const isDescontaNoDecimoTerceiro = value
              setData((prev) => ({ ...prev, isDescontaNoDecimoTerceiro }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
