import { Button, ConfirmDeleteDialog, ContainerTable, PageHeader, Stack } from '~/components'

import Form from './components/Form'
import Table from './components/Table'
import Dependentes from './components/Dependentes'

import useDialog from '~/hooks/useDialog'

import { useObterPorVinculo } from '~/hooks/queries/VinculoPensao/useObterPorVinculo'
import { useRemover } from '~/hooks/queries/VinculoPensao/useRemover'

import { VinculoPensao } from '~/hooks/queries/VinculoPensao/entities/VinculoPensao'

interface VinculoPensaoAlimenticiaProps {
  vinculoId: string
}

export default function VinculoPensaoAlimenticia({ vinculoId }: VinculoPensaoAlimenticiaProps) {
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoPensao | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closeDependentes,
    data: dataDependentes,
    isOpen: isOpenDependentes,
    open: openDependentes,
  } = useDialog('')

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorVinculo({
    params: {
      vinculoId,
    },
  })
  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemover()

  const data = _d || []

  function onItemClickTable(event: 'edit' | 'delete' | 'dependentes', value: VinculoPensao) {
    switch (event) {
      case 'dependentes':
        openDependentes(value.id)
        break
      case 'edit':
        openForm(value)
        break
      case 'delete':
        openConfirmDelete(value.id)
        break
    }
  }

  async function handleDeleteItem() {
    await mutateAsyncRemove({
      params: {
        id: dataConfirmDelete,
      },
    })
    closeConfirmDelete()
  }

  return (
    <Stack height="100%" py={1}>
      <PageHeader>
        <Button variant="contained" onClick={() => openForm(null)}>
          Adicionar
        </Button>
      </PageHeader>
      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          onItemClick={onItemClickTable}
        />
      </ContainerTable>

      {isOpenForm && (
        <Form data={dataForm} isOpen={isOpenForm} onClose={closeForm} vinculoId={vinculoId} />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
          isDeleting={isRemoving}
        />
      )}

      {isOpenDependentes && (
        <Dependentes
          isOpen={isOpenDependentes}
          onClose={closeDependentes}
          vinculoPensaoId={dataDependentes}
          vinculoId={vinculoId}
        />
      )}
    </Stack>
  )
}
