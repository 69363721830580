import { formatCurrency, formatSimpleDate } from '~/utils/utils'

import { ButtonBox, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import { IconButton } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ChildFriendly as ChildFriendlyIcon,
} from '@material-ui/icons'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoPensao } from '~/hooks/queries/VinculoPensao/entities/VinculoPensao'
import {
  IndPensaoAlimenticiaEnum,
  IndPensaoAlimenticiaLabel,
} from '~/@types/enums/IndPensaoAlimenticiaEnum'

interface TableProps {
  data: VinculoPensao[]
  isLoading: boolean
  isFetching: boolean
  onItemClick: (event: 'edit' | 'delete' | 'dependentes', value: VinculoPensao) => void
}

export default function Table({ data, isLoading, isFetching, onItemClick }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtInicio',
      label: 'Início',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'dtFim',
      label: 'Fim',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'indPensao',
      label: 'Indicador',
      options: {
        customBodyRender: (value: IndPensaoAlimenticiaEnum) => IndPensaoAlimenticiaLabel[value],
      },
    },
    {
      name: 'vrPensao',
      label: 'Valor',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'isDescontaNoDecimoTerceiro',
      label: 'Descontar 13°',
      options: {
        customBodyRender: (value) => <Ativo ativo={value} />,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Acessar os dependentes"
                color="primary"
                aria-label="Dependentes"
                onClick={() => {
                  const dt = data.find((d) => d.id === value)
                  if (dt) {
                    onItemClick('dependentes', dt)
                  }
                }}
              >
                <ChildFriendlyIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dt = data.find((d) => d.id === value)
                  if (dt) {
                    onItemClick('edit', dt)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const dt = data.find((d) => d.id === value)
                  if (dt) {
                    onItemClick('delete', dt)
                  }
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
    />
  )
}
