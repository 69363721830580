import React, { useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'
import PropType from 'prop-types'

export default function AutoCompletePessoaFisicaDependenteVinculo(props) {
  const { vinculoId, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    if (!vinculoId) return
    setLoading(true)
    try {
      const response = await api.get(`/PessoaFisicaDependente/GetByVinculo?vinculoId=${vinculoId}`)
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Dependente"
      {...rest}
    />
  )
}

AutoCompletePessoaFisicaDependenteVinculo.propTypes = {
  getOptionDisabled: PropType.func,
  renderOption: PropType.func,
  renderOptionComponent: PropType.func,
  getOptions: PropType.func,
  onChange: PropType.func,
  options: PropType.array,
  value: PropType.any,
  optionId: PropType.string,
  required: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  label: PropType.string,
  title: PropType.string,
  variant: PropType.string,
  disabled: PropType.bool,
  inputRef: PropType.any,
  InputLabelProps: PropType.any,
  ListboxComponent: PropType.any,
  ListboxProps: PropType.any,
  vinculoId: PropType.string,
}
