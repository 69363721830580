import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'

import { useInvalidate } from './useObterPorVinculo'

import { VinculoPensao } from './entities/VinculoPensao'

interface RequestProps {
  data: VinculoPensao
}

export function useCriar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/VinculoPensao/Criar', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
